import React, {useState} from 'react';
import {
    CustomInput,
    CustomMobileModal,
    CustomSelect,
    MobileMenuAddressesIcon,
    MobileMenuContactsIcon,
    MobileMenuDeliveryIcon,
    MobileMenuExitIcon,
    MobileMenuIcon,
    MobileMenuIconGrey,
    MobileMenuNotificationIcon,
    MobileMenuPersonalIcon,
    MobileOrderAddIcon,
    SideBarHomeIcon
} from "../helpers/common";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {DeliveryService} from "../services/DeliveryService";
import {CheckForPositiveNumbers, ValidatePhoneNumber} from "../helpers/helpers";
import {removeCookie} from "typescript-cookie";
import {access_token_name, refresh_token_name} from "../http";
import {OrdersService} from "../services/OrdersService";
import {CountriesService} from "../services/CountriesService";
import {TransportsService} from "../services/TransportsService";

const deliveryModalInitialState = {
    values: {
        address: '',
        phone: '',
        comment: '',
    },
    validation:{
        requestIsSent: false,
        modal_opened: false,
        type: '',
    }
}
const orderModalInitialState = {
    values:{
        tracking_number: '',
        title: '',
        quantity: '',
        sending_country: '',
        type_of_transport: '',
    },
    validation:{
        tracking_numberCheck: false,
        tracking_numberHelperText: '',
        modal_opened: false,
        requestIsSent: false,
    }
}
function FooterM() {
    const location = useLocation()
    const navigate = useNavigate()
    const [footerMenu, setFooterMenu] = useState(false)

    const [deliveryModal, setDeliveryModal] = useState(deliveryModalInitialState)

    const [orderModal, setOrderModal] = useState(orderModalInitialState)

    const submitDeliveryForm = (e: React.FormEvent)=>{
        e.preventDefault()

        setDeliveryModal({
            ...deliveryModal,
            validation: {
                ...deliveryModal.validation,
                requestIsSent: true,
            },
        })
        DeliveryService.SendRequest(deliveryModal.values).then(()=>{
            setDeliveryModal(deliveryModalInitialState)
        }).catch((err)=>{
            console.log(err)
        })
    }
    const submitOrderForm = (e: React.FormEvent)=>{
        e.preventDefault()

        setOrderModal({
            ...orderModal,
            validation: {
                ...orderModal.validation,
                requestIsSent: true,
            },
        })
        OrdersService.CreateOrder(orderModal.values).then(()=>{
            setOrderModal(orderModalInitialState)
            window.location.reload()
        }).catch((err)=>{
            console.log(err)
        })
    }

    const sending_countriesList = CountriesService.GetSendingCountriesList()
    const transports = TransportsService.GetTransportsList()
    return (
        <>
            <div className='fixed w-full z-99999 flex justify-center shadow-footerMobile items-center bottom-0 h-70px bg-white rounded-mFooterBorderRadius'>
                <div className='defaultWrapper'>
                    <div className='w-full h-full flex justify-between items-end pb-4'>
                        <Link to='/' className='flex flex-col justify-end items-center gap-1 cursor-pointer'>
                            <SideBarHomeIcon/>
                            <p className='text-gray-500 text-xs not-italic font-normal'>
                                Главная
                            </p>
                        </Link>
                        <div className='flex flex-col justify-end items-center gap-2.5 cursor-pointer' onClick={()=>{
                            setOrderModal({
                                ...orderModal,
                                validation: {
                                    ...orderModal.validation,
                                    modal_opened: !orderModal.validation.modal_opened,
                                }
                            })
                            setDeliveryModal({
                                ...deliveryModal,
                                validation: {
                                    ...deliveryModal.validation,
                                    modal_opened: false,
                                }
                            })
                            setFooterMenu(false)
                        }}>
                            <MobileOrderAddIcon/>
                            <p className='text-red-700 text-xs not-italic font-normal'>
                                Добавить посылку
                            </p>
                        </div>
                        <div className='flex flex-col justify-end items-center gap-1 cursor-pointer' onClick={()=>{
                            setFooterMenu(!footerMenu)
                            setOrderModal({
                                ...orderModal,
                                validation: {
                                    ...orderModal.validation,
                                    modal_opened: false,
                                }
                            })
                            setDeliveryModal({
                                ...deliveryModal,
                                validation: {
                                    ...deliveryModal.validation,
                                    modal_opened: false,
                                }
                            })
                        }}>
                            {footerMenu
                                ? <MobileMenuIcon/>
                                : <MobileMenuIconGrey/>
                            }
                            <p className={`${footerMenu ? 'text-yellow-400' : 'text-gray-500'} text-xs not-italic font-normal`}>
                                Меню
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <CustomMobileModal
                open={footerMenu}
                onClose={()=>{
                    setFooterMenu(false)
                }}
                children={
                    <div className='defaultWrapper'>
                        <div className='w-full h-full flex flex-col items-center justify-start gap-2.5 overflow-scroll'>
                            <Link to='/addresses'
                                  onClick={()=>{setFooterMenu(false)}}
                                  className={`${location.pathname.includes('addresses') ? 'bg-white border' : 'bg-gray-200'} rounded-xl shadow-default p-5 w-full flex justify-start items-center gap-5`}>
                                <MobileMenuAddressesIcon/>
                                <div className='flex flex-col justify-center items-start gap-2.5'>
                                    <p className='text-gray-900 text-sm not-italic font-medium'>
                                        Мои адреса
                                    </p>
                                </div>
                            </Link>
                            <div onClick={()=>{
                                setDeliveryModal({
                                    ...deliveryModal,
                                    validation: {
                                        ...deliveryModal.validation,
                                        modal_opened: true,
                                    }
                                })
                                setFooterMenu(false)
                                setOrderModal({
                                    ...orderModal,
                                    validation: {
                                        ...orderModal.validation,
                                        modal_opened: false,
                                    }
                                })
                            }}
                                 className='rounded-xl bg-gray-200 p-5 w-full flex justify-start items-center gap-5'>
                                <MobileMenuDeliveryIcon/>
                                <div className='flex flex-col justify-center items-start gap-2.5'>
                                    <p className='text-gray-900 text-sm not-italic font-medium'>
                                        Оформить доставку
                                    </p>
                                </div>
                            </div>
                            <Link to='/notifications'
                                  onClick={()=>{setFooterMenu(false)}}
                                  className={`${location.pathname.includes('notifications') ? 'bg-white border' : 'bg-gray-200'} rounded-xl shadow-default p-5 w-full flex justify-start items-center gap-5`}>
                                <MobileMenuNotificationIcon/>
                                <div className='flex flex-col justify-center items-start gap-2.5'>
                                    <p className='text-gray-900 text-sm not-italic font-medium'>
                                        Уведомления
                                    </p>
                                </div>
                            </Link>
                            <Link to='/personal'
                                  onClick={()=>{setFooterMenu(false)}}
                                  className={`${location.pathname.includes('personal') ? 'bg-white border' : 'bg-gray-200'} rounded-xl shadow-default p-5 w-full flex justify-start items-center gap-5`}>
                                <MobileMenuPersonalIcon/>
                                <div className='flex flex-col justify-center items-start gap-2.5'>
                                    <p className='text-gray-900 text-sm not-italic font-medium'>
                                        Личная страница
                                    </p>
                                </div>
                            </Link>
                            <Link to='/contacts'
                                  onClick={()=>{setFooterMenu(false)}}
                                  className={`${location.pathname.includes('contacts') ? 'bg-white border' : 'bg-gray-200'} rounded-xl shadow-default p-5 w-full flex justify-start items-center gap-5`}>
                                <MobileMenuContactsIcon/>
                                <div className='flex flex-col justify-center items-start gap-2.5'>
                                    <p className='text-gray-900 text-sm not-italic font-medium'>
                                        Контакты
                                    </p>
                                </div>
                            </Link>
                            <div className='rounded-xl bg-gray-200 mt-10 p-5 w-full flex justify-start items-center gap-5'
                                 onClick={()=>{
                                     removeCookie(access_token_name)
                                     removeCookie(refresh_token_name)
                                     navigate('/auth')
                                 }}
                            >
                                <MobileMenuExitIcon/>
                                <div className='flex flex-col justify-center items-start gap-2.5'>
                                    <p className='text-gray-600 text-sm not-italic font-medium'>
                                        Выйти
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />

            <CustomMobileModal
                open={deliveryModal.validation.modal_opened}
                onClose={()=>{
                    setDeliveryModal(deliveryModalInitialState)
                }}
                children={
                    <div className='defaultWrapper'>
                        <form onSubmit={submitDeliveryForm} className=' w-full h-full flex flex-col items-center justify-start gap-2.5 overflow-scroll'>
                            <h1 className='w-full text-center text-gray-900 text-3xl not-italic font-medium mb-10'>
                                Оформить доставку
                            </h1>

                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder={'Адрес'}
                                required={true}
                                label={'Адрес'}
                                value={deliveryModal.values.address}
                                onChange={(event)=>{
                                    setDeliveryModal({
                                        ...deliveryModal,
                                        values:{
                                            ...deliveryModal.values,
                                            address: event.target.value,
                                        }
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder={'Телефон'}
                                required={true}
                                label={'Телефон'}
                                value={deliveryModal.values.phone}
                                onChange={(event)=>{
                                    setDeliveryModal({
                                        ...deliveryModal,
                                        values:{
                                            ...deliveryModal.values,
                                            phone: ValidatePhoneNumber(event.target.value),
                                        }
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder={'Комментарий'}
                                required={false}
                                label={'Комментарий'}
                                value={deliveryModal.values.comment}
                                onChange={(event)=>{
                                    setDeliveryModal({
                                        ...deliveryModal,
                                        values:{
                                            ...deliveryModal.values,
                                            comment: event.target.value,
                                        }
                                    })
                                }}
                            />
                            <button type='submit' disabled={deliveryModal.validation.requestIsSent} className='submit-button_orange h-10 mt-10 flex-shrink-0'>
                                {deliveryModal.validation.requestIsSent
                                    ? <div className='loader'></div>
                                    : 'Оформить'
                                }
                            </button>
                        </form>
                    </div>
                }
            />

            <CustomMobileModal
                open={orderModal.validation.modal_opened}
                onClose={()=>{
                    setOrderModal(orderModalInitialState)
                }}
                children={
                    <div className='defaultWrapper'>
                        <form onSubmit={submitOrderForm} className='w-full h-full flex flex-col items-center justify-start gap-2.5 overflow-scroll'>
                            <h1 className='w-full text-center text-gray-900 text-3xl not-italic font-medium mb-10'>
                                Добавить посылку
                            </h1>

                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder={'Трек-номер'}
                                required={true}
                                label={'Трек-номер'}
                                value={orderModal.values.tracking_number}
                                onChange={(event)=>{
                                    setOrderModal({
                                        ...orderModal,
                                        values:{
                                            ...orderModal.values,
                                            tracking_number: event.target.value,
                                        }
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input'
                                type='text'
                                placeholder={'Наименование'}
                                required={true}
                                label={'Наименование'}
                                value={orderModal.values.title}
                                onChange={(event)=>{
                                    setOrderModal({
                                        ...orderModal,
                                        values:{
                                            ...orderModal.values,
                                            title: event.target.value,
                                        }
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input'
                                type='number'
                                placeholder={'Количество'}
                                required={true}
                                label={'Количество'}
                                value={orderModal.values.quantity}
                                onChange={(event)=>{
                                    setOrderModal({
                                        ...orderModal,
                                        values:{
                                            ...orderModal.values,
                                            quantity: CheckForPositiveNumbers(event.target.value),
                                        }
                                    })
                                }}
                            />
                            <CustomSelect
                                className='default-select'
                                placeholder='Страна'
                                label='Страна'
                                required={true}
                                value={orderModal.values.sending_country}
                                onChange={(event)=>{
                                    setOrderModal({
                                        ...orderModal,
                                        values:{
                                            ...orderModal.values,
                                            sending_country: event.target.value,
                                        }
                                    })
                                }}
                            >
                                <option value=""></option>
                                {!sending_countriesList.loading && !sending_countriesList.error && sending_countriesList.result?.data.map((country: any, i:number)=>(
                                    <option key={i} value={country.id}>{country.name}</option>
                                ))}
                            </CustomSelect>
                            <CustomSelect
                                className='default-select'
                                placeholder='Тип транспорта'
                                label='Тип транспорта'
                                required={true}
                                value={orderModal.values.type_of_transport}
                                onChange={(event)=>{
                                    setOrderModal({
                                        ...orderModal,
                                        values:{
                                            ...orderModal.values,
                                            type_of_transport: event.target.value,
                                        }
                                    })
                                }}
                            >
                                <option value=""></option>
                                {!transports.loading && !transports.error && transports.result?.data.map((transport: any, i:number)=>(
                                    <option key={i} value={transport.id}>{transport.name}</option>
                                ))}
                            </CustomSelect>
                            <button type='submit' disabled={orderModal.validation.requestIsSent} className='submit-button_orange h-10 mb-10 flex-shrink-0'>
                                {orderModal.validation.requestIsSent
                                    ? <div className='loader'></div>
                                    : 'Добавить'
                                }
                            </button>
                        </form>
                    </div>
                }
            />
        </>
    );
}

export default FooterM;