import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AddressesService} from "../../services/AddressesService";
import {CopyIcon} from "../../helpers/common";


export default function Addresses() {
    const user = useSelector((state: any) => state.user)
    const [countryCode, setCountryCode] = useState('')
    const [countryId, setCountryId] = useState('')

    const addressesList = AddressesService.GetAddressList({})

    useEffect(()=>{
        if(!addressesList.loading && !addressesList.error){
            const list = addressesList.result?.data.results
            list.forEach((address: any, index: number)=>{
                if(index === 0){
                    setCountryCode(address.sending_country?.code)
                    setCountryId(address.id)
                }
            })
        }
    },[addressesList.loading, addressesList.error])

    const copy = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>{
        const targetToCopy: any = event.currentTarget.previousElementSibling
        targetToCopy.select()
        document.execCommand("copy");
    }
    const copyAll = () => {
        const targetsToCopy: any = document.getElementsByClassName('address__receiverInfo')[0].children
        const allInputs: any = document.getElementById('SelectedInputs')
        const textToCopy = []
        for (let i = 0; i < targetsToCopy.length; i++) {
            if (targetsToCopy[i].children[0].innerText !== 'Город')
                textToCopy.push(`  ${targetsToCopy[i].children[0].innerText}: ${targetsToCopy[i].children[1].value}`)
        }
        allInputs.value = textToCopy.toString()
        allInputs.select()
        document.execCommand("copy");
    }
    return(
        <div className='componentMainWrapper'>
            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Адреса</h1>
            </div>

            <div className='w-full flex justify-start items-center gap-2.5 -mb-3'>
                {addressesList.loading
                    ?
                    <>
                        <div className='addressButton'>
                            -------
                        </div>
                        <div className='addressButton'>
                            -------
                        </div>
                        <div className='addressButton'>
                            -------
                        </div>
                        <div className='addressButton'>
                            -------
                        </div>
                    </>
                    : addressesList.error
                        ? 'error'
                        : addressesList.result?.data.results.map((address: any, index:number)=>(
                            <div className={`addressButton ${countryId === address.id ? 'addressButton_active' : ''}`}
                                 key={index}
                                 onClick={()=>{
                                 setCountryCode(address.sending_country?.code)
                                 setCountryId(address.id)
                                 }}
                            >
                                {address.sending_country.name} ({address.type_of_transport.name})
                            </div>
                        ))
                }

            </div>

            {addressesList.loading
            ?
            <div className='flex w-full flex-col justify-start items-start bg-white rounded-xl p-8 mb-12 shadow-default'>
                <div className='w-full flex justify-between items-start'>
                    <p className='text-gray-900 text-base not-italic font-bold'>
                        Клиентский код: ---
                    </p>
                    <div className='flex items-start gap-7'>
                        <div className='flex flex-col justify-start items-start gap-2.5'>
                            <p className='text-gray-900 text-base not-italic font-bold'>
                                Доставка:
                            </p>
                            <span className='text-gray-900 text-xs not-italic font-normal'>
                                ---
                            </span>
                        </div>
                        <div className='flex flex-col justify-start items-start gap-2.5'>
                            <p className='text-gray-900 text-base not-italic font-bold'>
                                Страна:
                            </p>
                            <span className='text-gray-900 text-xs not-italic font-normal'>
                                ---
                            </span>
                        </div>
                        <div className='flex flex-col justify-start items-start gap-2.5'>
                            <p className='text-gray-900 text-base not-italic font-bold'>
                                Тариф:
                            </p>
                            <span className='text-gray-900 text-xs not-italic font-normal'>
                                ---
                            </span>
                        </div>
                    </div>
                </div>
                <h3 className='text-yellow-400 text-base not-italic font-bold mb-2 mt-10'>
                    Информация о получателе
                </h3>
                <div className='address__receiverInfo'>
                    <div>
                        <p>Получатель</p>
                        <input type="text" value={`---`}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Адрес 1</p>
                        <input type="text" value={'---'}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Адрес 2</p>
                        <input type="text" value={`---`}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Город</p>
                        <input type="text" value={'---'}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Почтовый индекс</p>
                        <input type="text" value={'---'}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Номер телефона</p>
                        <input type="text" value={'---'}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                </div>
            </div>
             : addressesList.error
                ? addressesList.error.message
                :
            addressesList.result?.data.results.map((currentAddress: any, index: number)=> currentAddress.id === countryId && (
            <div className='flex w-full flex-col justify-start items-start bg-white rounded-xl p-8 mb-12 shadow-default'>
                <div className='w-full flex justify-between items-start'>
                    <p className='text-gray-900 text-base not-italic font-bold'>
                        Клиентский код: {currentAddress.prefix}{user.code}
                    </p>
                    <div className='flex items-start gap-7'>
                        <div className='flex flex-col justify-start items-start gap-2.5'>
                            <p className='text-gray-900 text-base not-italic font-bold'>
                                Доставка:
                            </p>
                            <span className='text-gray-900 text-xs not-italic font-normal'>
                                {currentAddress.delivery_period}
                            </span>
                        </div>
                        <div className='flex flex-col justify-start items-start gap-2.5'>
                            <p className='text-gray-900 text-base not-italic font-bold'>
                                Страна:
                            </p>
                            <span className='text-gray-900 text-xs not-italic font-normal'>
                                {currentAddress.sending_country.name}
                            </span>
                        </div>
                        <div className='flex flex-col justify-start items-start gap-2.5'>
                            <p className='text-gray-900 text-base not-italic font-bold'>
                                Тариф:
                            </p>
                            <span className='text-gray-900 text-xs not-italic font-normal'>
                                {currentAddress.tariff}$
                            </span>
                        </div>
                    </div>
                </div>
                <h3 className='text-yellow-400 text-base not-italic font-bold mb-2 mt-10'>
                    Информация о получателе
                </h3>
                {currentAddress.sending_country.code === 'TUR'
                ?<div className='address__receiverInfo'>
                    <div>
                        <p>Получатель</p>
                        <input type="text" value={`${currentAddress.receiver}${currentAddress.prefix} ${user.code}`}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Address 1</p>
                        <input type="text" value={currentAddress.address1}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Address 2</p>
                        <input type="text" value={`${currentAddress.address2} ${user.phone}`}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Şehir</p>
                        <input type="text" value={currentAddress.city}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Posta kodu</p>
                        <input type="text" value={currentAddress.zip_code}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                     <div>
                        <p>Ilce</p>
                        <input type="text" value={currentAddress.state}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                     <div>
                        <p>Mahalle</p>
                        <input type="text" value={currentAddress.semt}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Telefon numarası</p>
                        <input type="text" value={currentAddress.phone_number}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                </div>
                :<div className='address__receiverInfo'>
                    <div>
                        <p>Получатель</p>
                        <input type="text" value={`${currentAddress.receiver}${currentAddress.prefix} ${user.code}`}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Адрес 1</p>
                        <input type="text" value={currentAddress.address1}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Адрес 2</p>
                        <input type="text" value={`${currentAddress.address2} ${user.phone}`}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Город</p>
                        <input type="text" value={currentAddress.city}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Почтовый индекс</p>
                        <input type="text" value={currentAddress.zip_code}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                    <div>
                        <p>Номер телефона</p>
                        <input type="text" value={currentAddress.phone_number}/>
                        <button className='' onClick={copy}>
                            <CopyIcon/>
                        </button>
                    </div>
                </div>
                }
            </div>
            ))
            }



            <div className="receiverBox-infoBox__buttons">
                <button onClick={copyAll} className='cancel-button_orange h-10'>
                    Cкопировать весь адрес
                </button>
                <input type="text" readOnly={true} value='' id='SelectedInputs'
                       style={{
                           width: '1px',
                           height: '1px',
                           overflow: 'hidden'
                       }}/>
            </div>
        </div>
    )
}