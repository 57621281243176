import React from "react";
import {FacebookIcon, InstagramIcon, TelegramIcon} from "../../helpers/common";


export default function Contacts() {
    return (
        <div className='componentMainWrapper'>
            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Контакты</h1>
            </div>

            <div
                className='flex w-full flex-col justify-start items-start bg-white rounded-xl p-5 mb-12 shadow-default'>
                <div className='contacts__box p-10 grid grid-cols-2 gap-7 bg-white'>
                    <div>
                        <p>Контакты</p>
                        <span>+996551555858</span>
                    </div>
                    <div>
                        <p>График работы</p>
                        <span>09:00  - 18:00 (пн-сб), вс - выходной</span>
                    </div>
                    <div>
                        <p>Адрес</p>
                        <span>Бишкек, ул. Токтогула 216</span>
                    </div>
                    <div>
                        <p>Соцсети</p>
                        <span className='flex gap-2'>
                            <a href="https://www.instagram.com/yldam.express.kg">
                                <InstagramIcon/>
                            </a>
                            <a href="https://www.facebook.com/erlauul">
                                <FacebookIcon/>
                            </a>
                            <a href="https://t.me/+999099001">
                                <TelegramIcon/>
                            </a>


                        </span>
                    </div>
                </div>
            </div>

            <div
                className='flex w-full flex-col justify-start items-start bg-white rounded-xl p-5 mb-12 shadow-default'>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28975.112070368363!2d74.6118316073185!3d42.862381852842944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb633eb4a865f%3A0xda48af2b2a2e4b59!2z0JLQtdGE0LAg0KbQtdC90YLRgA!5e0!3m2!1sru!2skg!4v1694505657420!5m2!1sru!2skg"
                    width="100%" height="331" style={{border: 0}} allowFullScreen={true} loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
    )
}